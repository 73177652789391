import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
// bootstrap ----------------------
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
// css ---------------------------
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Header = () => {
	const url = typeof window !== 'undefined' ? window.location.pathname : '';
	// detecta la cantidad de pixeles que se ha hecho scroll -----------------------------
	const [navBackground, setNavBackground] = useState(false)
	const navRef = useRef()
	navRef.current = navBackground


	useEffect(() => {
		const handleScroll = () => {
			const show = window.scrollY > 50
			if (navRef.current !== show) {
				setNavBackground(show)
			}
		}
		document.addEventListener('scroll', handleScroll)
		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
	}, [])
	// -----------------------------------------------------------------------

	// detecta el tamano de la ventana width y height -----------------------------
	const size = useWindowSize();

	function useWindowSize() {
		// Initialize state with undefined width/height so server and client renders match
		// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
		const [windowSize, setWindowSize] = useState({
			width: undefined,
			height: undefined,
		});

		useEffect(() => {
			// Handler to call on window resize
			function handleResize() {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}

			// Add event listener
			window.addEventListener("resize", handleResize);

			// Call handler right away so state gets updated with initial window size
			handleResize();

			// Remove event listener on cleanup
			return () => window.removeEventListener("resize", handleResize);
		}, []); // Empty array ensures that effect is only run on mount

		return windowSize;
	}
	// --------------------------------------------------------------------

	const LoginButton = styled(Nav.Link)`
		font-weight: 700!important;
		padding-top:0;
		color: #7C7C7D !important;
		${navBackground ? "font-size:.8rem !important;" : "font-size:1rem !important;"}
	`;


	return (

		<Navbar collapseOnSelect expand="xl" className={`${!navBackground ? "bg-transparen" : "bg-light shadow-nav"} fixed-top`} css={css`transition:all ease .5s;`}>
			<Container className="container-xl">
				<Navbar.Brand href="/">
					<img
						src={size.width < 1200 ? '/favicon.png' : '/SocialEnergy_logo_color.png'}
						css={css`
						
						@media(max-width:1200px){
							max-width:150px;
						}
						transition: all 0.2s ease-in;
						${size.width < 1200 ?
								navBackground ? "max-width:50px !important;" : "max-width:50px !important;"
								:
								navBackground ? "max-width:200px !important;" : "max-width:200px !important;"
							}
							${navBackground ?
								size.width > 1200 ?
									navBackground ? "max-width:150px !important;" : "max-width:250px !important;"
									:
									navBackground ? "max-width:50px !important;" : "max-width:50px !important;"
								:
								null
							}
					`}
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbarResponsive" />
				<Navbar.Collapse id="navbarResponsive">
					<Nav as="ul" className="m-auto">
						<Nav.Item as="li">
							<Link
								css={css`
									${navBackground ? "font-size:.8rem !important;" : "font-size:1rem !important;"}
									transition: all 0.2s ease-in;
								`}
								to="/" className={`nav-link font-weight-bold mx-1 ${url === '/' ? "active" : ""}`}>INICIO
							 </Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Link
								css={css`
									${navBackground ? "font-size:.8rem !important;" : "font-size:1rem !important;"}
									transition: all 0.2s ease-in;
								`}
								to="/nosotros" className={`nav-link font-weight-bold mx-1 ${url === '/nosotros' ? "active" : ""}`}>NOSOTROS
							 </Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Link
								css={css`
									${navBackground ? "font-size:.8rem !important;" : "font-size:1rem !important;"}
									transition: all 0.2s ease-in;
								`}
								to="/empresas" className={`nav-link font-weight-bold mx-1 ${url === '/empresas' ? "active" : ""}`}>EMPRESAS
							 </Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Link
								css={css`
									${navBackground ? "font-size:.8rem !important;" : "font-size:1rem !important;"}
									transition: all 0.2s ease-in;
								`}
								to="/cliente-libre" className={`nav-link font-weight-bold mx-1 ${url === '/cliente-libre' ? "active" : ""}`}>CLIENTE LIBRE
							 </Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Link
								css={css`
									${navBackground ? "font-size:.8rem !important;" : "font-size:1rem !important;"}
									transition: all 0.2s ease-in;
								`}
								to="/contacto" className={`nav-link font-weight-bold mx-1 ${url === '/contacto' ? "active" : ""}`}>CONTACTO
							 </Link>
						</Nav.Item>
						<Nav.Item as="li" className="d-block d-xl-none">
							<Link
								css={css`
									${navBackground ? "font-size:.8rem !important;" : "font-size:1rem !important;"}
									transition: all 0.2s ease-in;
								`}
								to="#" className="nav-link font-weight-bold mx-1 mx-lx-3">INICIAR SESION
							 </Link>
						</Nav.Item>
					</Nav>
					<Nav className="d-none d-xl-block">
						<LoginButton href="#"><Button variant="primary" className="border-radius-20">INICIAR SESION</Button></LoginButton>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Header;





