import React from 'react';
import Helmet from 'react-helmet'
import { css, Global } from '@emotion/react'
//Components
import Header from "./header/header";
import Footer from "./footer/footer";


const Layout = ({ children }) => {
	return (
		<>
			<Global styles={css`
				body, html{
					transition: all ease .5s !important;
					padding:0!important;
					overflow-x:hidden !important;
				}   
				.border-radius-20{
					border-radius:20px;
				}

				.btn-primary{
					background-color:#0050E9 !important;
					border-color:#0050E9 !important;
				}

				.shadow-nav{
					box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
				}

				.btn{
					font-weight:700 !important;
					font-family: Open Sans;
					padding:5px 20px;
				}
				.navbar{
					padding: .5rem 1rem !important;
					@media (max-width:1200px){
						background:rgba(255,255,255,1)
					}
				}
				.modal-open .modal{
					padding:0 !important;
				}
				@media (min-width: 1200px){
					.container, .container-lg, .container-md, .container-sm, .container-xl {
						max-width: 1480px !important;
					}
				}
				@media (max-width: 992px){
                        h1, h2 {
                            font-size: 3.2rem !important;
                        }
                    }

                    @media (max-width: 576px){
                        h1, h2 {
                            font-size: 2.8rem !important;
                        }
						p{
							font-size:1rem !important;
						}
                    }
                    @media (max-width: 478px){
                        h1,h2 {
                            font-size: 1.5rem !important;
                        }
                        h3 {
                            font-size: 1rem !important;
                        }
                    }
    		`}
			/>
			<Helmet>
				<title>Social Energy</title>
				<meta name="description" content="Sitio web social energy" />
				<link rel="preconnect" href="https://fonts.gstatic.com" />
				<link href="https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Open+Sans&family=Roboto&display=swap" rel="stylesheet" />
			</Helmet>
			<Header />
			<main>
				{children}
			</main>
			<Footer />
		</>
	)
}

export default Layout
