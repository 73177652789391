import React from 'react';
import { Link } from 'gatsby';
// bootstrap ----------------------
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
// css ---------------------------
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Header = () => {




    const Footer = styled.footer`
		background: linear-gradient(360deg, #E1F095 0%, rgba(225, 240, 149, 0) 96.35%);
        /* padding-bottom:5rem; */
	`;
    const TitlesFooter = styled.h3`
		font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        color: #0050E9;
        a{
            color: #0050E9;
        }
        @media (max-width:768px){
            margin-top:3rem;
        }
	`;
    const TextFooter = styled(Link)`
		font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        display: flex;
        cursor: pointer;
        color: #0050E9;
        a{
            color: #0050E9;
        }
        @media (max-width:768px){
            justify-content:center;
        }

	`;
    const InputFooter = styled(Form.Control)`
		background: #E5F2A3;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius:0 !important;
        border:0 !important;
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(0, 80, 233, 0.75);
            font-size: 1.2rem;
            opacity: 1; /* Firefox */
            text-align:center;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: rgba(0, 80, 233, 0.75);
            font-size: 1.2rem;
            text-align:center;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: rgba(0, 80, 233, 0.75);
            font-size: 1.2rem;
            text-align:center;
        }
	`;
    const InputButtonFooter = styled(Button)`
		background: #0050E9;
        border-radius: 40px;
        width:100%;
	`;
    const Copy = styled.p`
		font-family: Gloria Hallelujah;
        font-size: 1.5rem;
        color: #FFFFFF;
        @media (max-width:992px){
            font-size:1.2rem !important;
        }
        @media (max-width:520px){
            font-size:1rem !important;
        }
        @media (max-width:441px){
            font-size:.9rem !important;
        }
        @media (max-width:405px){
            font-size:.8rem !important;
        }
        @media (max-width:360px){
            font-size:.7rem !important;
        }
	`;
    const ImgBear = styled.img`
        width: 50px;height: 30px;
        @media (max-width:520px){
            width: 40px;height: 20px;
        }
        @media (max-width:405px){
            width: 30px;
            height: 15px;
        }
        @media (max-width:360px){
            width: 30px;
            height: 15px;
        }
        @media (max-width:325px){
            width: 25px;
        }
	`;

    const ComtainerLogo = styled(Col)`
		@media (max-width:992px){
            justify-content:center;
        }
	`;
    const RowForm = styled(Row)`
		@media (max-width:1200px){
            justify-content:center !important;
        }
	`;
    const ImageSocial = styled.img`
		
	`;
    const ContainerIconSocial = styled.div`
		@media (max-width:768px){
            justify-content:center;
        }
	`;


    return (

        <Footer>
            <Container>
                <RowForm>
                    <ComtainerLogo xl={3} lg={4} md={6} sm={6} xs={7} className="mt-5">
                        <img alt="Logo" className="img-fluid w-100" src={'/SocialEnergy_logo_color.png'} />
                    </ComtainerLogo>
                </RowForm>
                <Row className="pb-5 pt-0 mt-md-5 justify-content-center">
                    <Col xl={6} lg={12}>
                        <Row className="justify-content-center">
                            <Col lg={6} md={4} sm={12} className="text-center justify-content-center">
                                <TitlesFooter className="text-center text-md-left"><Link to="/">Social Energy</Link></TitlesFooter>
                                {/* <TitlesFooter className="text-center text-md-left"><Link to="/">Inicio</Link></TitlesFooter> */}
                                <TextFooter to="/nosotros" className="text-center text-md-left">Nosotros</TextFooter>
                                <TextFooter to="/empresas" className="text-center text-md-left">Empresas</TextFooter>
                                <TextFooter to="/cliente-libre" className="text-center text-md-left">Cliente Libre</TextFooter>
                                <TextFooter to="/contacto" className="text-center text-md-left">Contacto</TextFooter>
                            </Col>

                            <Col lg={6} md={4} sm={6} xs={8} className="text-center justify-content-center">
                                <TitlesFooter className="text-center text-md-left">CONTACTO</TitlesFooter>
                                <ContainerIconSocial className="d-flex text-center">
                                    {/* <ImageSocial alt="Logo" className="img-fluid m-2" src={'/facebook.png'} />
                                    <ImageSocial alt="Logo" className="img-fluid m-2" src={'/twitter.png'} /> */}
                                    <ImageSocial alt="Logo" className="img-fluid m-2" src={'/instagram.png'} />
                                    <ImageSocial alt="Logo" className="img-fluid m-2" src={'/map.png'} />
                                </ContainerIconSocial>
                                <ContainerIconSocial className="d-flex text-center">
                                    <ImageSocial alt="Logo" className="img-fluid m-2" src={'/message.png'} />
                                    <ImageSocial alt="Logo" className="img-fluid m-2" src={'/phone.png'} />
                                </ContainerIconSocial>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={6} lg={12}>
                        <RowForm className="justify-content-end">
                            <Col xl={6} lg={6}>
                                <TitlesFooter className="text-center text-md-left">¿Deseas una muestra?</TitlesFooter>
                                <Form>
                                    <Form.Group controlId="formBasicEmail">
                                        <InputFooter placeholder="Introduce tu email" className="my-4" />
                                        <InputButtonFooter variant="primary" type="submit">
                                            SOLICITAR
                                        </InputButtonFooter>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </RowForm>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="pt-2" css={css`border-top: 1px solid #FFFFFF;`}>
                <Row className="justify-content-center">
                    <Col xl="auto" className="pr-3 pr-lg-0">
                        <Copy className="text-center mb-0">
                            Designed by <span><a href="https://aurorastudio.tech/" className="font-weight-bold text-white" css={css`font-family:Open Sans;`} target="_blank">Aurora Studio</a></span>, California Division <span><ImgBear alt="bear" className="img-fluid m-2" src={'/bear.png'} /></span>
                        </Copy>
                    </Col>
                </Row>
            </Container>
        </Footer>
    );
}

export default Header;





